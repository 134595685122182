/* noto-sans-jp-regular - japanese_latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url('_assets/font/noto-sans-jp-v23-japanese_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Regular'), local('NotoSansJapanese-Regular'),
       url('_assets/font/noto-sans-jp-v23-japanese_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('_assets/font/noto-sans-jp-v23-japanese_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('_assets/font/noto-sans-jp-v23-japanese_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('_assets/font/noto-sans-jp-v23-japanese_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('_assets/font/noto-sans-jp-v23-japanese_latin-regular.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-700 - japanese_latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url('_assets/font/noto-sans-jp-v23-japanese_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Bold'), local('NotoSansJapanese-Bold'),
       url('_assets/font/noto-sans-jp-v23-japanese_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('_assets/font/noto-sans-jp-v23-japanese_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('_assets/font/noto-sans-jp-v23-japanese_latin-700.woff') format('woff'), /* Modern Browsers */
       url('_assets/font/noto-sans-jp-v23-japanese_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('_assets/font/noto-sans-jp-v23-japanese_latin-700.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}

body{
  font-family: 'Roboto', 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &.msieError{
    #intro{
      display: none;
    }
    #wrapper{
      display: none;
    }
  }
  #msieError{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @include pc{
      padding: 0 60px;
    }
    @include sp{
      padding: 0 8vw;
    }
    p{
      text-align: center;
      line-height: 2em;
      @include pc{
        font-size: 15px;
      }
      @include sp{
        font-size: 4vw;
      }
    }//p
  }//#msieError
  &:not(.single-works){
    background-color: #222;
    color: rgba(#fff, .85);
  }
  #intro{
    width: 100%;
    height: 100vh;
    background-color: #222;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 1;
    visibility: visible;
    transition: opacity 1s ease, visibility 1s ease;
    &.intro-hide{
      opacity: 0;
      visibility: hidden;
    }//&.intro-hide
    .intro_inner{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .intro_logo, .intro_text{
        >img{
          width: 100%;
          height: auto;
          vertical-align: middle;
        }//img
      }
      .intro_logo{
        @include sp{
          width: 40vw;
        }
        @include pc{
          width: 160px;
        }
      }//.intro_logo
      .intro_text{
        @include sp{
          margin-top: 10.666666666666668vw;
          width: 53.333333333333336vw
        }
        @include pc{
          width: 400px;
          margin-top: 40px;
        }
      }//.intro_text
    }//.intro_inner
  }//#intro
  #wrapper{
    width: 100%;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    &.intro{
      height: 100vh;
      overflow: hidden;
    }
    #globalHeader{
      #globalHeader_menu{
        position: fixed;
        z-index: 4;
        @include sp{
          width: 10.666666666666668vw;
          height: 10.666666666666668vw;
          top: 5.333333333333334vw;
          left: 5.333333333333334vw;
        }
        @include pc{
          width: 60px;
          height: 60px;
          top: 30px;
          left: 30px;
          cursor: pointer;
          opacity: 1;
          transition: opacity .3s ease;
          &:hover{
            opacity: .5;
          }
          &.pcMenuOpen{
            display: none;
          }
        }
        >svg{
          width: 100%;
          height: auto;
          vertical-align: middle;
        }//img
      }//#globalHeader_menu
    }//#globalHeader
    #globalNavi{
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3;
      transition: opacity .3s ease, visibility .3s ease;
      &:not(.show){
        opacity: 0;
        visibility: hidden;
      }
      &.show{
        opacity: 1;
        visibility: visible;
      }
      &:not(.single-works){
        background-color: #222;
      }
      @include pc{
        &.pcMenuOpen{
          opacity: 1;
          visibility: visible;
          background-color: rgba(#222, .8);
        }
      }
      ul.menu{
        display: flex;
        align-items: center;
        @include pc{
          justify-content: center;
          height: 120px;
        }
        @include sp{
          flex-direction: column;
          padding: 16vw 0 10.666666666666668vw;
        }
        >li{
          @include pc{
            opacity: 1;
            transition: opacity .3s ease;
            &:hover{
              opacity: .5;
            }
          }
          &.active{
            a{
              p{
                text-decoration: line-through;
              }
            }
          }
          &+li{
            @include pc{
              margin-left: 60px;
            }
            @media screen and (min-width: 769px) and (max-width: 1000px){
              margin-left: 4vw;
            }
            @include sp{
              margin-top: 8vw;
            }
          }
          >a{
            >p{
              line-height: 1em;
              @include pc{
                font-size: 15px;
              }
              @include sp{
                font-size: 4vw;
              }
            }//p
          }//a
        }//li
      }//ul.menu
      .logoMark{
        @include sp{
          height: 20vw;
          margin: 0 auto;
          padding: 0 0 16vw 0;
          box-sizing: content-box;
          text-align: center;
        }
        @include pc{
          height: 60px;
          position: fixed;
          z-index: 4;
          top: 30px;
          right: 30px;
          cursor: pointer;
          opacity: 1;
          transition: opacity .3s ease;
          &:hover{
            opacity: .5;
          }
        }
        >a{
          >img{
            width: auto;
            height: 100%;
            vertical-align: middle;
          }//img
        }//a
      }//.logoMark
    }//#globalNavi
    #wrap{
      margin-bottom: auto;
      @include pc{
        &.pcMenuOpen{
          padding-top: 120px;
        }
      }
    }//#wrap
    #indexNews{
      width: 100%;
      background-color: #222;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 4;
      transition: opacity .3s ease, visibility .3s ease;
      &:not(.show){
        opacity: 0;
        visibility: hidden;
      }
      &.show{
        opacity: 1;
        visibility: visible;
      }
      .indexNews-inner{
        width: 100%;
        @include sp{
          height: 21.333333333333336vw;
        }
        @include pc{
          height: 90px;
        }
        position: relative;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        box-sizing: content-box;
        .newsLink{
          @include sp{
            display: none;
          }
          @include pc{
            @include middle(absolute);
            right: 30px;
            z-index: 2;
          }
          >a{
            font-size: 15px;
            line-height: 1em;
            text-decoration: underline;
            opacity: 1;
            transition: opacity .3s ease;
            &:hover{
              opacity: .5;
            }
            &::after{
              content: ">";
              display: inline-block;
              padding-left: 0.5em;
            }
          }//a
        }//.newsLink
        .swiper-container{
          @include sp{
            width: inherit;
          }
          @include pc{
            width: calc(100% - 113px);
            opacity: 1;
            transition: opacity .3s ease;
            &:hover{
              opacity: .5;
            }
          }
          height: inherit;
          .swiper-wrapper{
            width: 100%;
            height: inherit;
            .swiper-slide{
              width: inherit;
              height: inherit;
              &.swiper-slide-duplicate-active, &.swiper-slide-active{
                pointer-events: auto;
              }
              &:not(.swiper-slide-duplicate-active):not(.swiper-slide-active){
                pointer-events: none;
              }
              a.news{
                width: inherit;
                height: inherit;
                overflow: hidden;
                background-color: #222;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include sp{
                  padding: 0 5.333333333333334vw;
                }
                @include pc{
                  padding: 0 30px;
                }
                p{
                  line-height: 1em;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  @include sp{
                    font-size: 3.2vw;
                  }
                  @include pc{
                    font-size: 12px;
                  }
                  &+p{
                    @include sp{
                      margin-top: 1.3333333333333335vw;
                    }
                    @include pc{
                      margin-top: 5px;
                    }
                  }
                }//p
              }//.news
            }//.swiper-slide
          }//.swiper-wrapper
        }//.swiper-container
      }//.indexNews-inner
    }//#indexNews
    footer#globalFooter{
      width: 100%;
      @include sp{
        padding: 21.333333333333336vw 0;
      }
      @include pc{
        padding: 120px 0;
      }
      .logoMark{
        text-align: center;
        @include sp{
          height: 20vw;
        }
        @include pc{
          height: 150px;
        }
        >a{
          display: inline-block;
          height: inherit;
          @include pc{
            cursor: pointer;
            opacity: 1;
            transition: opacity .3s ease;
            &:hover{
              opacity: .5;
            }
          }
          >img{
            width: auto;
            height: 100%;
            vertical-align: middle;
          }//img
        }//a
      }//.logoMark
      .copy{
        line-height: 1em;
        text-align: center;
        @include sp{
          font-size: 2.666666666666667vw;
          margin-top: 5.333333333333334vw;
        }
        @include pc{
          font-size: 10px;
          margin-top: 20px;
        }
      }
      .privacy{
        opacity: .5;
        line-height: 1em;
        text-align: center;
        @include sp{
          font-size: 2.666666666666667vw;
          margin-top: 5.333333333333334vw;
        }
        @include pc{
          font-size: 10px;
          margin-top: 20px;
        }
        a{
          @include pc{
            opacity: 1;
            transition: opacity .3s ease;
            &:hover{
              opacity: .5;
            }
          }
        }
      }
    }//footer#globalFooter
    #pageUp{
      @include sp{
        display: none;
      }
      @include pc{
        position: fixed;
        z-index: 2;
        right: 30px;
        bottom: 30px;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s ease;
        &:hover{
          opacity: .5;
        }
        &:not(.single-works){
          svg{
            rect{
              fill: #222;
            }
            polygon{
              fill: #fff;
              fill-opacity: .85;
            }
          }//svg
        }
      }
    }//#pageUp
  }//#wrapper
}//body
