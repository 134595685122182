/*!
Theme Name: こびとのくつ
Author: Minami Sekino
Description:
*/
@import "reset";
@import "module";
@import "init";
@import "main";
@import "front-page";
@import "archive-works";
@import "single-works";
@import "archive-news";
@import "single-news";
@import "page-company";
@import "page-recruit";
@import "archive-articles";
@import "single-articles";
@import "related-posts";
@import "page-privacy";
