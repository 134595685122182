$minW: 768px;
$maxW: 1440px;

@mixin sp {
  @media screen and (max-width: $minW) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: #{$minW + 1}) {
    @content;
  }
}

@mixin middle($vmpos) {
  position: $vmpos;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center($hspos) {
  position: $hspos;
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerMiddle($cmpos) {
  position: $cmpos;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@mixin calcMarginTop($marginTop, $fontSize, $lineHeight) {
  margin-top: calc( #{$marginTop} - ( ( #{$fontSize} * ( #{$lineHeight} - 1 ) ) / 2 ) );
}
